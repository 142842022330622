<template>
  <div class="master-toko">
    <header-page>
      <span class="size14 fw-bold-700" style="color: #0B5FFF;margin-right: 5px;">Master</span>
      <span class="text-dark text-darken-5" style="margin-right: 5px;">></span>
      <span class="text-dark fw-bold-400 size14">Bundle</span>
    </header-page>

    <Table :result="result" :delete-data="deleteData" :get-data="getData" :edit-item="editItem" @filter="getFilter" />

    <Modal :form-data="formPayload" :create-item="createItem" :edit-id="editId" :edit-item="editItem"
      :update-item="updateItem" :clean-up-form="cleanUpForm" :icon-bank="iconBank" :is-loading="isLoading"
      @getPayload="getPayload" />

  </div>
</template>

<script>
// eslint-disable-next-line import/no-extraneous-dependencies
import _ from 'lodash'
import {
  VBToggle,
} from 'bootstrap-vue'
import { checkPermission, successNotification, errorNotification } from '@/auth/utils'

import HeaderPage from '@/components/HeaderPage.vue'
import Table from '@/components/Bundle/Table.vue'
import Modal from '@/components/Bundle/Modal/index.vue'

export default {
  title() {
    return 'Master Kategori'
  },
  components: {
    HeaderPage,
    Table,
    Modal,
  },
  directives: {
    'b-toggle': VBToggle,
  },
  data() {
    return {
      currentPage: 1,
      isLoading: true,
      result: {},
      validations: [],
      editId: null,
      iconBank: null,
      formPayload: {},
      filter: {
        search: '',
      },
      payloadItems: []
    }
  },
  setup() {
    return {
      checkPermission,
      successNotification,
      errorNotification,
    }
  },
  watch: {
    filter: {
      handler: _.debounce(function () {
        this.getData()
      }, 300),
      deep: true,
    },
  },
  created() {
    this.getData()
  },
  methods: {
    getPayload(value) {
      this.formPayload = value
    },
    getFilter(value) {
      this.filter = value
    },
    cleanUpForm() {
      this.editId = null
      this.iconBank = null
      this.formPayload = {
        items: []
      }
      this.validations = ''
    },
    editItem(item) {
      // console.log(item, 'ITEMSS ');
      this.cleanUpForm()

      this.$http.get(`product-bundles/${item.uuid}`).then(result => {

        const { products, name, image, price, start_date, end_date, is_no_period } = result.data.data

        const formattedProducts = products.map((item, index) => ({
          id: item.id,  // Adding 1 to index to start IDs from 1
          qty: item.qty,
          product_uuid: {
            name: item.product.alias_name,
            uuid: item.product.uuid,
            standard_price: item.product.standard_price,
            // id: item.id
          },
          warehouse_uuid: item.warehouse.uuid,
        }));

        this.editId = item.uuid
        this.formPayload = {
          items: formattedProducts,
          name,
          price,
          start_date,
          end_date,
          is_no_period
        }

        this.$bvModal.show('modal-bundle')
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    async getData(page = 1) {
      this.isLoading = true
      const queryParams = this.filter
      queryParams.page = page
      this.$http.get('product-bundles', { params: queryParams }).then(result => {
        this.isLoading = false
        this.result = result.data
        this.currentPage = result.data.current_page
      }).catch(err => {
        // eslint-disable-next-line no-console
        console.log(err)
      })
    },
    createItem() {
      const form = this.preparePayload()
      this.isLoading = true
      this.$http.post('product-bundles', form)
        .then(() => {
          this.getData()
          successNotification(this, 'Success', 'Bundle berhasil di buat')
          this.cleanUpForm()
          this.$bvModal.hide('modal-bundle')
          this.isLoading = false
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
          }
          this.isLoading = false
        })
    },
    updateItem() {
      const form = this.preparePayload()
      this.isLoading = true
      form.append('_method', 'PATCH')

      this.$http.post(`product-bundles/${this.editId}`, form)
        .then(() => {
          this.getData()
          successNotification(this, 'Success', 'Bundle berhasil di ubah!')
          this.cleanUpForm()
          this.isLoading = false
          this.$bvModal.hide('modal-bundle')
        })
        .catch(error => {
          if (error.response.data.meta.validations) {
            this.validations = error.response.data.meta.validations
            errorNotification(this, 'Oops!', error.response.data.meta.messages)
          }
          this.isLoading = false
        })
    },
    async deleteData(uuid) {
      this.$swal({
        title: 'Are you sure?',
        text: 'Are you sure to delete this item?',
        icon: 'warning',
        showCancelButton: true,
        customClass: {
          confirmButton: 'btn btn-primary',
          cancelButton: 'btn btn-outline-danger ml-1',
        },
        buttonsStyling: false,
      }).then(result => {
        if (result.value) {
          this.$http.delete(`product-bundles/${uuid}`)
            .then(() => {
              this.getData()
              this.$swal({
                icon: 'success',
                title: 'Success!',
                text: 'Bundle berhasil dihapus',
                customClass: {
                  confirmButton: 'btn btn-success',
                },
              })
            })
            .catch(error => {
              if (error.response.data.meta.messages.length > 0) {
                errorNotification(this, 'Oops!', error.response.data.meta.messages)
              }
            })
        }
      })
    },
    preparePayload() {
      const form = new FormData()
      // eslint-disable-next-line no-restricted-syntax
      for (const key in this.formPayload) {
        // eslint-disable-next-line no-prototype-builtins
        if (this.formPayload.hasOwnProperty(key) && this.formPayload[key] != null && !Array.isArray(this.formPayload[key])) {
          if (key != 'is_no_period') {
            form.append(key, this.formPayload[key]);
          }
          if (key == 'is_no_period') {
            form.append(key, this.formPayload[key] ? 1 : 0);
          }
        }
      }
      if (this.formPayload.items?.length) {
        for (let i = 0; i < this.formPayload.items.length; i++) {
          const item = this.formPayload.items[i];
          for (const key of Object.keys(item)) {
            form.append(`products[${i}][${key}]`, key === 'product_uuid' ? item[key]?.uuid : item[key]);
          }
        }
      }

      return form
    },
  },
}
</script>

<style lang="scss">
@import '@/assets/scss/variables/_variables.scss';

.px-33 {
  padding-left: 33px;
  padding-right: 33px;
}

.px-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.py-16 {
  padding-top: 16px;
  padding-bottom: 16px;
}

.py-18 {
  padding-top: 18px;
  padding-bottom: 18px;
}


.master-toko {
  height: 100vh
}
</style>
